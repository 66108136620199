import React, { Component } from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import './Title.css';

class Title extends Component {
  render () {
    return (
      <Container>
        <Row>
          <Col id="text" sm="7">
            <Image src="/bilder/Taktstock.png" fluid />
          </Col>
          <Col id="bild" sm="5">
            <Image src="/bilder/Dirigent.png" fluid />
          </Col>
        </Row>
      </Container>
    ) 
  }
}

export default Title;
