import React,{ Component } from 'react';
import "bootstrap-css-only/css/bootstrap.min.css";
import './App.css';
import Mailform from './Mailform.js';
import Title from './Title.js';

class App extends Component {
  render() {
    return(
      <React.Fragment>
        <Title />
        <Mailform />
      </React.Fragment>
    )
  }
}

export default App;
