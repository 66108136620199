import React, { Component } from 'react';
import { Container, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import './Mailform.css';

class Mailform extends Component {
  constructor(props) {
    super(props);
    this.state = {
      absender: '',
      betreff: '',
      nachricht: '',
      empfaenger: 'office@taktstock-edition.eu',
      mailSent: false,
      error: null,
      modus: 3    // 1 = nur Entwickler, 2 = +Absender, 3 = alle, 4 = ohne Entwickler
    }
  }

  handleFormSubmit( event ) {
    event.preventDefault();
    axios.post( 'https://mailer.web66.info', this.state )
        .then( result => { this.setState( {
            mailSent: result.data.sent,
            error: result.data.message } )
        })
        .catch( error => this.setState( { error: error.message } ) );
    console.log( this.state );
  };

  render() {
    return (
      <Container>
        <Form className="form">
            <Form.Label>Ihre Nachricht an uns:</Form.Label>
            <Form.Control type="email" required
                placeholder={ 'Ihre Mailadresse...' }
                onChange = {e=>this.setState({ absender:e.target.value })} />
            <Form.Control type="text" required
                placeholder={ 'Ihr Betreff...' }
                onChange = {e=>this.setState({ betreff:e.target.value })} />
            <Form.Control as="textarea" rows="3" required
                placeholder={ 'Ihre Nachricht...' }
                onChange = {e=>this.setState({ nachricht:e.target.value })} />
            <Button type="submit" onClick={e=>this.handleFormSubmit(e)} >
                Absenden
            </Button>
            <div>
                { this.state.mailSent && 
                <div> Danke für Ihre Nachricht! </div> }
            </div>
        </Form>
      </Container>
    )
  }
}

export default Mailform;
